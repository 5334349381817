import React, { useState } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {
  Container,
  VideoContainer,
  SingleVideoCotnainer,
} from "../components/common/layout"
import colors from "../components/common/colors"
import { SubHeading } from "../components/common/copy"
import { useQueryParam, StringParam } from "use-query-params"
import { StaticQuery, graphql } from "gatsby"

export const PassWord = styled.input`
  height: 30px;
  border: solid 1px #c0c0c0;
  border-radius: 3px;
`

export const PassWordLabel = styled.label`
  color: ${colors.secondary};
  padding-bottom: 5px;
`

export const ButtonContainer = styled.div`
  display: flex;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const Button = styled.button`
  background-color: ${colors.primary};
  border: none;
  margin-right: 5px;
  background-image: linear-gradient(
    150deg,
    ${colors.secondary} 50%,
    transparent 50%
  );
  background-position: 100%;
  background-size: 400%;
  transition: background 500ms ease-in-out;
  position: relative;
  color: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  margin-top: 10px;
  position: relative;
  font-size: 1rem;
  text-decoration: none;
  &:hover {
    background-position: 0;
    cursor: pointer;
  }
`

const LiveSubHeading = styled(SubHeading)`
  text-align: center;
  /* margin-top: 0; */
  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
    padding-bottom: 10px;
    font-weight: 400;
    margin-top: 40px;
  }
`
const ChooseRoom = ({ selectRoom }) => (
  <>
    <LiveSubHeading>Please choose a room</LiveSubHeading>
    <ButtonContainer>
      <Button
        onClick={() => {
          selectRoom({ twitch: "ascpinkroom", name: "Pink Room" })
        }}
      >
        Pink Room
      </Button>
      <Button
        style={{ background: "green" }}
        onClick={() => {
          selectRoom({ twitch: "ascgreenroom", name: "Green Room" })
        }}
      >
        Green Room
      </Button>
      <Button
        style={{ background: "black" }}
        onClick={() => {
          selectRoom({ twitch: "ascstudio1", name: "Studio 1" })
        }}
      >
        Studio 1
      </Button>
    </ButtonContainer>
  </>
)

const LiveStream = () => {
  const [room, setRoom] = useState()
  const [cheetoPw, setCheetoPW] = useState("")
  const [key, setKey] = useQueryParam("key", StringParam)
  const possiblePw = ["7865717778", `dance${new Date().getDate() + 1}`]
  return (
    <StaticQuery
      query={graphql`
        query qrCodeKey {
          airtable(table: { eq: "liveQrCodeKey" }) {
            data {
              liveQrCodeKey
            }
          }
        }
      `}
      render={({ airtable }) => (
        <Layout hidefreeflass>
          <Seo title="Live Stream" />
          <Container column flex height="40vh" pb="0">
            {possiblePw.indexOf(cheetoPw) < 0 &&
              key !== airtable.data.liveQrCodeKey && (
                <>
                  <LiveSubHeading>
                    For the safety of our dancers, this section of our website
                    is password protected.
                  </LiveSubHeading>
                  <LiveSubHeading
                    style={{ marginTop: "0", marginBottom: "20px" }}
                  >
                    Please contact the front desk for assistance.
                  </LiveSubHeading>
                  <PassWordLabel htmlFor="password">Password</PassWordLabel>
                  <PassWord
                    name="password"
                    type="password"
                    value={cheetoPw}
                    onChange={e => setCheetoPW(e.target.value)}
                  />
                </>
              )}

            {(possiblePw.indexOf(cheetoPw) >= 0 ||
              key === airtable.data.liveQrCodeKey) && (
              <>
                {room ? (
                  <Video room={room} />
                ) : (
                  <ChooseRoom selectRoom={setRoom} />
                )}
              </>
            )}
          </Container>
        </Layout>
      )}
    />
  )
}

const Video = ({ room }) => (
  <>
    <LiveSubHeading>{room.name}</LiveSubHeading>
    <VideoContainer>
      <SingleVideoCotnainer>
        <iframe
          title="twich live stream for room"
          src={`https://player.twitch.tv/?channel=${room.twitch}&parent=doraldance.com&muted=true`}
          height="720"
          width="1280"
          frameborder="0"
          scrolling="no"
          allowfullscreen="true"
        ></iframe>
      </SingleVideoCotnainer>
    </VideoContainer>
  </>
)

export default LiveStream
